import { defineStore } from "pinia";
import { useAccountStore } from "./account";
import workflowMixins from "@/mixins/workflow";
import type { AxiosResponse } from "openapi-client-axios";
import { WorkflowType } from "@/components/workflow/Types";
import { BatchStatus } from "@/components/workflow/PaymentFlow/Types";
import type { Components, Paths } from "../../src/assets/docs/client.d.ts";
import { WithIssuer } from "../assets/docs/client";

export interface WorkflowState {
  all: Components.Schemas.G2fpModelsViewModelsAppWebFlowVm | null;
  overview: Paths.GetWorkflowOverview.Responses.$200 | null;
  types: Components.Schemas.G2fpModelsFlowWorkflowTypesCountDto | [];
  allTypes: Components.Schemas.G2fpModelsFlowWorkflowTypesCountDto | [];
  info: Components.Schemas.G2fpModelsFlowWorkflowCoreShadowDtoWorkflowInstanceDto | {};
  allowedEvents: array<string> | [];
  attachedFiles: Paths.GetFileInfo.Responses.$200 | [];
  paymentFlowAccountsData: Paths.GetPaymentFlowAccountsData.Responses.$200 | [];
  paymentFlowTypes: Paths.GetPaymentFlowTypes.Responses.$200 | [];
  paymentFlow: Paths.GetPaymentFlowVm.Responses.$200 | {};
  orderAcceptanceCreate: Paths.GetOrderAcceptanceCreateVm.Responses.$200 | {};
  orderAcceptanceEdit: Paths.GetOrderAcceptanceEditAmVm.Responses.$200 | {};
  orderAcceptanceTemplates: Paths.GetOrderAcceptanceTemplates.Responses.$200 | null;
  orderAcceptanceTemplatesArchived: Paths.GetOrderAcceptanceTemplates.Responses.$200 | null;
  primaryBookingRequestFlow: Paths.GetExistingPrimaryBookingRequestFlowVm.Responses.$200 | {};
  productIssuanceRequestFlow: Paths.GetProductIssuanceFlowVm.Responses.$200 | {};
  secondaryIncreaseDecreaseFlow: Paths.GetIspSecondaryChangeFlowVm.Responses.$200 | {};
  pendingStepVM: Components.Schemas.G2fpModelsFlowFlowsEventDto | {};
  product: Paths.GetBasicProductInfo.Responses.$200 | {};
  publicDocuments: Paths.ListPublicDocuments.Responses.$200 | null;
  hasGenTwoIssuer: boolean | null;
  hasGenTwoDigitalIssuer: boolean | null;
  canProcessWorkflow: boolean | null;
  flowState: Paths.GetFlowStateData.Responses.$200 | null;
  assetDataUpdateFlow: Paths.GetAssetDataUpdateFlowVm.Responses.$200 | {};
  approvalFlow: Paths.GetApprovalFlowVm.Responses.$200 | {};
  productApprovalProcessFlow: Paths.GetProductApprovalProcessFlowVm.Responses.$200 | {};
  entityApprovalFlow: Paths.GetEntityApprovalFlowVm.Responses.$200 | {};
  productUnderlyings: Paths.GetProductUnderlyings.Responses.$200 | null;
  couponFlow: Paths.GetCouponFlowVm.Responses.$200 | {};
  couponFlowTypes: Paths.GetAvailableCouponFlowTypes.Responses.$200 | null;
  allCouponFlowTypes: Paths.GetAllAvailableCouponFlowTypes.Responses.$200 | null;
  couponFlowTypesLoading: boolean | false;
  allCouponFlowTypesLoading: boolean | false;
  plannedCoupons: Paths.GetPlannedCoupons.Responses.$200 | null;
  productTerminationFlow: Paths.GetProductTerminationFlowVm.Responses.$200 | {};
  partialRedemptionEventFlow: Paths.GetPartialRedemptionEventFlowVm.Responses.$200 | {};
  batchProcessSelected: Array<Components.Schemas.G2fpModelsViewModelsAppWebFlowVm> | null;
  allBatches: Array<object> | null;
  allBatchesTotalCount: null;
  batchInfo: {};
  loading: boolean | false;
}

export const useWorkflowStore = defineStore("WorkflowStore", {
  state: (): WorkflowState => ({
    all: null,
    overview: null,
    types: [],
    allTypes: [],
    info: {},
    allowedEvents: [],
    attachedFiles: [],
    paymentFlowAccountsData: [],
    paymentFlowTypes: [],
    paymentFlow: {},
    orderAcceptanceCreate: {},
    orderAcceptanceEdit: {},
    orderAcceptanceTemplates: null,
    orderAcceptanceTemplatesArchived: null,
    primaryBookingRequestFlow: {},
    productIssuanceRequestFlow: {},
    secondaryIncreaseDecreaseFlow: {},
    pendingStepVM: {},
    product: {},
    publicDocuments: null,
    hasGenTwoIssuer: null,
    hasGenTwoDigitalIssuer: null,
    canProcessWorkflow: null,
    flowState: null,
    assetDataUpdateFlow: {},
    approvalFlow: {},
    productApprovalProcessFlow: {},
    entityApprovalFlow: {},
    productUnderlyings: null,
    couponFlow: {},
    couponFlowTypes: null,
    allCouponFlowTypes: null,
    couponFlowTypesLoading: false,
    allCouponFlowTypesLoading: false,
    plannedCoupons: null,
    productTerminationFlow: {},
    partialRedemptionEventFlow: {},
    batchProcessSelected: null,
    allBatches: null,
    allBatchesTotalCount: null,
    batchInfo: {},
    loading: false,
  }),

  getters: {
    pendingFlows(state) {
      return (
        state.overview && [
          ...(state.overview.myPendingFlows || []),
          ...(state.overview.groupPendingFlows || []),
          ...(state.overview.companyPendingFlows || []),
        ]
      );
    },
  },

  debounce: {
    loadAll: 250,
    load: [250, { before: true }],
  },

  actions: {
    setWorkflows(workflows) {
      this.all = workflows;
    },
    setTypes(types) {
      this.types = types;
    },
    setWorkflow(workflow) {
      this.info = workflow;
    },
    resetWorkflowVMs() {
      this.info = {};
      this.allowedEvents = [];
      this.attachedFiles = [];
      this.paymentFlow = {};
      this.primaryBookingRequestFlow = {};
      this.productIssuanceRequestFlow = {};
      this.secondaryIncreaseDecreaseFlow = {};
      this.pendingStepVM = {};
      this.canProcessWorkflow = null;
      this.assetDataUpdateFlow = {};
      this.approvalFlow = {};
      this.productApprovalProcessFlow = {};
      this.entityApprovalFlow = {};
      this.couponFlow = {};
      this.product = {};
    },
    reset() {
      this.all = null;
      this.overview = null;
      this.types = [];
      this.info = {};
      this.allowedEvents = [];
      this.attachedFiles = [];
      this.paymentFlowAccountsData = [];
      this.paymentFlowTypes = [];
      this.paymentFlow = {};
      this.orderAcceptanceCreate = {};
      this.orderAcceptanceEdit = {};
      this.orderAcceptanceTemplates = null;
      this.orderAcceptanceTemplatesArchived = null;
      this.primaryBookingRequestFlow = {};
      this.productIssuanceRequestFlow = {};
      this.secondaryIncreaseDecreaseFlow = {};
      this.pendingStepVM = {};
      this.product = {};
      this.publicDocuments = null;
      this.hasGenTwoIssuer = null;
      this.hasGenTwoDigitalIssuer = null;
      this.canProcessWorkflow = null;
      this.flowState = null;
      this.assetDataUpdateFlow = {};
      this.approvalFlow = {};
      this.productApprovalProcessFlow = {};
      this.entityApprovalFlow = {};
      this.productUnderlyings = null;
      this.couponFlowTypes = null;
      this.allCouponFlowTypes = null;
      this.couponFlowTypesLoading = false;
      this.allCouponFlowTypesLoading = false;
      this.plannedCoupons = null;
      this.productTerminationFlow = {};
      this.partialRedemptionEventFlow = {};
      this.allBatches = null;
      this.allBatchesTotalCount = null;
      this.batchProcessSelected = null;
      this.batchInfo = {};
    },

    async loadAll(payload) {
      this.loading = true;

      const response = await this.apiClient.GetContributedFlowsForUser(payload, null, {
        timeout: 300000,
      });

      this.setWorkflows(response.data.flows);

      // add currently selected type, in case there's no results
      if (payload?.workflowDefinitionId) {
        const workflowTypeHasResults = response.data?.flowTypes?.some(
          type => type.workflowDefinitionId === payload.workflowDefinitionId,
        );

        if (!workflowTypeHasResults) {
          const selectedWorkflowType = this.allTypes?.find(
            type => type.workflowDefinitionId === payload?.workflowDefinitionId,
          );
          response.data.flowTypes.push({
            ...selectedWorkflowType,
            count: 0,
          });
        }
      }
      this.setTypes(response.data.flowTypes);
      this.loading = false;
    },
    async loadOverview() {
      this.loading = true;
      const response = await this.apiClient.GetWorkflowOverview(null, null, {
        timeout: 300000,
      });

      this.overview = response.data;
      this.loading = false;
    },
    async loadAllTypes() {
      // Temporary until there's a dedicated endpoint
      this.loading = true;
      const response = await this.apiClient.GetContributedFlowsForUser(
        { limit: 0, dataRange: 4 }, // limit to 0
      );

      this.allTypes = response.data.flowTypes;
      this.loading = false;
    },
    async loadAllBatches(payload) {
      this.loading = true;
      const response = await this.apiClient.GetBatchPaymentFlows(payload);

      if (response.status === 200) {
        // parse batches status
        this.allBatches = response.data.flows?.map(batch => ({
          ...batch,
          status: helpers.getBatchStatus(batch),
        }));

        this.allBatchesTotalCount = response.data.totalItemsCount;
      }
      this.loading = false;
    },
    async loadBatch(id, payload) {
      this.loading = true;
      const response = await this.apiClient.GetFlowVmByIdPosApi(id);

      if (response.status === 200) {
        this.batchInfo = {
          ...response.data,
          status: helpers.getBatchStatus(response.data),
        };

        const paymentInstructionsResponse = await this.apiClient.GetFlowsByIds(
          null,
          this.batchInfo.data?.paymentInstructionIds,
        );

        this.batchProcessSelected = paymentInstructionsResponse.data.flows;
      }
      this.loading = false;
    },
    async load(id) {
      this.loading = true;

      if (this.info.id && id !== this.info.id) {
        this.info = {};
        this.attachedFiles = [];
      }

      const response = await this.apiClient.GetWorkflowVmById(id);

      if (response.status === 200) {
        const isin = response.data?.workflow.data.isin;
        if (isin) {
          const productResponse = await this.apiClient.GetBasicProductInfo(isin);
          this.product = productResponse.data;
        }
        // TEMPORARY HACK to populate the initial step eventData
        if (response.data?.workflow.data.allEvents) {
          response.data.workflow.data.allEvents[0].eventData = {
            effectiveDateUtc: response.data.workflow.createTime,
          };

          if (response.data.workflow.data.processedEvents?.length > 0) {
            // Check if there are skipped/hidden steps on allEvents
            for (const [
              index,
              processedEvent,
            ] of response.data.workflow.data.processedEvents.entries()) {
              const allEvents = response.data.workflow.data.allEvents;
              if (
                // "index + 1" because processedEvents don't have the initiation step, so we skip it
                allEvents[index + 1]?.eventId !== processedEvent.eventId &&
                // "index + 2" because we want to check if only one step was skipped
                // so we look if the next index event is equal to processedEvent
                allEvents[index + 2]?.eventId === processedEvent.eventId
              ) {
                allEvents.splice(index + 1, 1);
              }
            }
          }
        }

        this.setWorkflow(response.data.workflow);
        this.allowedEvents = response.data.allowedEvents;
        this.canProcessWorkflow = response.data.canProcessWorkflow;

        if (this.info.data.attachedFileGuids && this.info.data.attachedFileGuids.length > 0) {
          this.loadAttachedFiles(this.info.data.attachedFileGuids);
        }
      }
      this.loading = false;
    },
    async loadAttachedFiles(filesGuids) {
      const promises = filesGuids.map(fileGuid => this.apiClient.GetFileInfo(fileGuid));
      const responses = await Promise.all(promises);
      this.attachedFiles = responses.map(response => response.data);
    },
    async loadPaymentFlowAccountsData(isin) {
      if (this.paymentFlowAccountsData.length > 0) {
        this.paymentFlowAccountsData = [];
      }
      const response = await this.apiClient.GetPaymentFlowAccountsData(isin);

      this.paymentFlowAccountsData = response.data;
    },
    async loadPaymentFlow(params) {
      const response = await this.apiClient.GetPaymentFlowVm(params);
      this.paymentFlow = response.data;
    },
    async loadPrimaryBookingRequestFlow(id) {
      this.info = {};
      this.attachedFiles = [];
      let response: AxiosResponse;
      if (id !== undefined && id !== "") {
        response = await this.apiClient.GetExistingPrimaryBookingRequestFlowVm(id);
      } else {
        response = await this.apiClient.GetBlankPrimaryBookingRequestFlowVm();
      }

      this.primaryBookingRequestFlow = response.data;

      response.data.allEvents.shift();
      response.data.allEvents.unshift({
        eventId: "PayingAgentOrderSubmission",
        eventDescription: "Paying Agent Order Submission",
        currentStep: 0,
      });

      const workflowTemplate = {
        workflowDefinitionId: "PrimaryBookingRequestFlow",
        data: {
          workflowDisplayName: "Primary Booking Request",
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };

      this.setWorkflow(workflowTemplate);
    },
    async clonePrimaryBookingRequestFlow(id) {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetPrimaryBookingRequestCloneFlowVm(id);
      this.primaryBookingRequestFlow = response.data;

      response.data.allEvents.shift();
      response.data.allEvents.unshift({
        eventId: "PayingAgentOrderSubmission",
        eventDescription: "Paying Agent Order Submission",
        currentStep: 0,
      });

      const workflowTemplate = {
        workflowDefinitionId: "PrimaryBookingRequestFlow",
        data: {
          workflowDisplayName: "Primary Booking Request",
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };

      this.setWorkflow(workflowTemplate);
    },
    async remind(payload) {
      await this.apiClient.SendWorkflowReminderNotification({
        workflowId: payload.workflowId,
      });
    },
    async initiatePrimaryBookingRequestFlow(payload) {
      this.resetWorkflowVMs();

      const response = await this.apiClient.InitiatePrimaryBookingRequestFlow(null, {
        ...payload,
        initiatorUserName: useAccountStore().user.userName,
      });
      return response;
    },
    async initiatePayingAgentAccountOpeningFlow(
      isin: string,
      withProduct: boolean,
      withBrokerCustodian: boolean,
      assignAllToInitiator: boolean,
    ) {
      return await this.apiClient.InitiatePayingAgentAccountOpeningFlow({
        isin,
        withProduct,
        withBrokerCustodian,
        assignAllToInitiator,
      });
    },
    async getFlowVmForAnotherWorkflowStep(id: string) {
      const response = await this.apiClient.GetFlowVmByIdPosApi(id);

      return response.data;
    },
    async loadOrderAcceptanceCreate() {
      this.info = {};
      this.attachedFiles = [];

      const workflowTemplate = {
        workflowDefinitionId: "OrderAcceptanceTemplateFlow",
        data: {
          workflowDisplayName: "Order Acceptance Template",
          pendingEvent: {
            eventId: "OrderGatheringCreate",
            eventDescription: "Paying Agent Order Gathering",
            currentStep: 0,
          },
          processedEvents: [],
          remainingEvents: [
            {
              eventId: "ProductManagerAcceptance",
              eventDescription: "Product Manager Acceptance",
            },
          ],
        },
      };

      this.setWorkflow(workflowTemplate);

      const response = await this.apiClient.GetOrderAcceptanceCreateVm();
      this.orderAcceptanceCreate = response.data;
    },
    async initiateOrderAcceptanceCreate(payload) {
      const response = await this.apiClient.InitiateOrderAcceptanceCreate(null, payload);
      return response;
    },
    async loadOrderAcceptanceEditPayingAgent(id) {
      // if id not present it's a refresh update
      if (id) {
        const workflowTemplate = {
          workflowDefinitionId: "OrderAcceptanceTemplateFlow",
          data: {
            //workflowDisplayName: response.data.template.name,
            pendingEvent: {
              eventId: "OrderGatheringEdit",
              eventDescription: "Paying Agent Order Gathering",
              currentStep: 0,
            },
            processedEvents: [],
            remainingEvents: [
              {
                eventId: "ProductManagerAcceptance",
                eventDescription: "Product Manager Acceptance",
              },
            ],
          },
        };
        this.setWorkflow(workflowTemplate);
      }

      const response = await this.apiClient.GetOrderAcceptanceEditPayingAgentVm(
        id || this.orderAcceptanceEdit.template.id,
      );

      if (response.status === 200) {
        if (this.info && this.info.data) {
          this.info.data.workflowDisplayName = response.data.template.name;
        }
        response.data.isPayingAgentView = true;

        this.orderAcceptanceEdit = response.data;
      }
    },
    async sendReminderMessage(id) {
      await this.apiClient.SendReminderMessage(id?.id || this.orderAcceptanceEdit.template.id);
    },
    async loadOrderAcceptanceEditAssetManager(id) {
      // if id not present it's a refresh update
      if (id) {
        const workflowTemplate = {
          workflowDefinitionId: "OrderAcceptanceTemplateFlow",
          data: {
            pendingEvent: {
              eventId: "OrderGatheringEdit",
              eventDescription: "Asset Manager Order Gathering",
              currentStep: 0,
            },
            processedEvents: [],
            remainingEvents: [
              {
                eventId: "ProductManagerAcceptance",
                eventDescription: "Product Manager Acceptance",
              },
            ],
          },
        };
        this.setWorkflow(workflowTemplate);
      }

      const response = await this.apiClient.GetOrderAcceptanceEditAmVm(
        id || this.orderAcceptanceEdit.template.id,
      );

      if (response.status === 200) {
        if (this.info && this.info.data) {
          this.info.data.workflowDisplayName = response.data.template.name;
        }
        this.orderAcceptanceEdit = response.data;
      }
    },
    async loadOrderAcceptanceTemplates(dateRange = 5) {
      this.loading = true;
      const response = await this.apiClient.GetOrderAcceptanceTemplates(
        {
          dataRange: dateRange,
        },
        null,
        {
          timeout: 300000,
        },
      );
      this.orderAcceptanceTemplates = response.data.templates;
      this.loading = false;
    },
    async loadOrderAcceptanceTemplatesArchived(dateRange = 5) {
      this.loading = true;
      const response = await this.apiClient.GetOrderAcceptanceTemplates(
        {
          dataRange: dateRange,
          isArchived: true,
        },
        null,
        {
          timeout: 300000,
        },
      );
      this.orderAcceptanceTemplatesArchived = response.data.templates;
      this.loading = false;
    },
    async createOrderAcceptanceOrder(payload) {
      return await this.apiClient.CreateOrderAcceptanceOrder(null, payload);
    },
    async deleteOrderAcceptanceOrder(payload) {
      return await this.apiClient.DeleteOrderAcceptance(null, payload);
    },
    async updateOrderAcceptanceTemplate(payload) {
      return await this.apiClient.UpdateOrderAcceptanceTemplate(payload.id, payload);
    },
    async loadTermsheetApprovalFlow(payload) {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetProductIssuanceIsinAvailableFlowVm(payload);

      delete response.data.allEvents[0].assignedDateUtc;

      this.setWorkflow({
        workflowDefinitionId: "ProductIssuanceIsinAvailableFlow",
        data: {
          workflowDisplayName: response.data.workflowDisplayName,
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          allEvents: response.data.allEvents,
        },
      });
      this.termsheetApprovalFlow = response.data;
    },
    async cloneTermsheetApprovalFlow(id) {
      this.loadTermsheetApprovalFlow({ cloneId: id });
    },
    async initiateProductIssuanceFlow(payload) {
      const response = await this.apiClient.InitiateProductIssuanceIsinAvailableFlow(null, {
        ...payload,
        initiatorUserName: useAccountStore().user.userName,
      });
      return response;
    },
    async loadProductIssuanceRequestFlow() {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetProductIssuanceFlowVm();

      delete response.data.allEvents[0].assignedDateUtc;

      this.setWorkflow({
        workflowDefinitionId: "ProductIssuanceFlow",
        data: {
          workflowDisplayName: response.data.workflowDisplayName,
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          allEvents: response.data.allEvents,
        },
      });
      this.productIssuanceRequestFlow = response.data;
    },
    async orderAcceptanceProcessOrder(payload) {
      const response = await this.apiClient.ProcessOrderAcceptance(null, payload);

      if (response.status === 200) {
        this.orderAcceptanceEdit = response.data;
      }

      return response;
    },
    async loadProductAdditionalData(isin) {
      //this.productAdditionalData = {};

      const response = await this.apiClient.GetAdditionalProductDataForIsin(isin);

      //this.setProductAdditionalData(response.data);
      return response.data;
    },
    async initiateProductIssuanceRequestFlow(payload) {
      const response = await this.apiClient.InitiateProductIssuanceFlow(null, {
        ...payload,
        initiatorUserName: useAccountStore().user.userName,
      });
      return response;
    },
    async loadSecondaryIncreaseDecreaseFlow(id) {
      this.info = {};
      this.attachedFiles = [];

      let response: AxiosResponse;
      if (id !== undefined && id !== "") {
        response = await this.apiClient.GetIspSecondaryChangeFlowVmFromTemplate(id);
      } else {
        response = await this.apiClient.GetIspSecondaryChangeFlowVm();
      }

      this.secondaryIncreaseDecreaseFlow = response.data;

      response.data.allEvents.shift();
      response.data.allEvents.unshift({
        eventId: "PayingAgentSecSubmission",
        eventDescription: "Paying Agent (Sec. Submission)",
        currentStep: 0,
      });

      const workflowTemplate = {
        workflowDefinitionId: "IspSecondaryChangeFlow",
        data: {
          workflowDisplayName: response.data.workflowDisplayName,
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };

      this.setWorkflow(workflowTemplate);
    },
    async cloneSecondaryIncreaseDecreaseFlow(id) {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetIspSecondaryChangeFlowCloneVm(id);
      this.secondaryIncreaseDecreaseFlow = response.data;

      response.data.allEvents.shift();
      response.data.allEvents.unshift({
        eventId: "PayingAgentSecSubmission",
        eventDescription: "Paying Agent (Sec. Submission)",
        currentStep: 0,
      });

      const workflowTemplate = {
        workflowDefinitionId: "IspSecondaryChangeFlow",
        data: {
          workflowDisplayName: response.data.workflowDisplayName,
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };

      this.setWorkflow(workflowTemplate);
    },
    async loadPaymentInstructionFlow(payload) {
      this.info = {};
      this.attachedFiles = [];
      this.paymentFlowAccountsData = [];
      this.paymentFlowTypes = [];

      const response = await this.apiClient.GetPaymentFlowVm(payload);
      this.paymentFlow = response.data;

      response.data.allEvents.shift();
      response.data.allEvents.unshift({
        eventId: "ProductManager",
        eventDescription: "Product Manager Payment Entry",
        currentStep: 0,
      });

      const workflowTemplate = {
        workflowDefinitionId: "PaymentFlow",
        isInitatedFromQuarterlies: !!payload?.quarterliesFlowId,
        data: {
          workflowDisplayName: response.data.workflowDisplayName,
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },
    async clonePaymentInstructionFlow(id) {
      this.loadPaymentInstructionFlow({ cloneId: id });
    },
    async clonePartialRedemptionEventFlow(id) {
      this.loadPartialRedemptionEventFlow({ cloneId: id });
    },
    async cloneCouponFlow(id) {
      this.loadCouponFlow({ cloneId: id });
    },
    async initiatePaymentInstructionFlow(payload) {
      const response = await this.apiClient.InitiatePaymentFlowNew(null, {
        ...payload,
        initiatorUserName: useAccountStore().user.userName,
      });
      this.resetWorkflowVMs();
      return response;
    },
    async initiateSecondaryIncreaseDecreaseFlow(payload) {
      const response = await this.apiClient.InitiateIspSecondaryChangeFlow(null, {
        ...payload,
        initiatorUserName: useAccountStore().user.userName,
      });
      if (response.status == 200) {
        this.resetWorkflowVMs();
      }
      return response;
    },
    async loadTermsheetIssuerSubrelationApproval(id) {
      this.pendingStepVM = {};

      const response = await this.apiClient.GetIssuerSubrelationApprovalVm(id);

      this.pendingStepVM = response.data;
    },
    async updateTermsheetIssuerSubrelationApproval(payload) {
      const response = await this.apiClient.ProcessIssuerSubrelationApprovalVm(null, payload);
      return response;
    },
    async uploadFile({ file, fileType, flowId }) {
      const formData = new FormData();
      const guid = workflowMixins.methods.generateGuid();
      formData.append("guid", guid);
      formData.append("file", file);
      if (fileType) {
        formData.append("fileType", fileType);
      }
      if (flowId) {
        formData.append("flowId", flowId);
      }

      const response = await this.apiClient.UploadFile(null, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return {
        ...response,
        guid: guid,
      };
    },
    // avoid: legacy code, will be removed
    async loadPendingStepVM({ type, id }) {
      this.pendingStepVM = {};

      const response = await this.apiClient.paths[`/apptwo/flow/${type}vm/{id}`].get({
        id: id,
      });

      return (this.pendingStepVM = response.data);
    },
    async updatePendingStepVM({ type, payload }) {
      return await this.apiClient.paths[`/apptwo/flow/${type}vm`].post(null, payload);
    },
    async cancelWorkflow(payload) {
      return await this.apiClient.CancelWorkflow(null, payload);
    },
    async reassign(payload) {
      return await this.apiClient.ReassignWorkflow(null, payload);
    },
    async terminate(payload) {
      return await this.apiClient.TerminateWorkflow(null, payload);
    },
    async loadPublicDocuments() {
      const response = await this.apiClient.ListPublicDocuments();
      this.publicDocuments = response.data;
    },
    async loadHasIssuer() {
      const response = await this.apiClient.GetIssuerTypes();

      this.hasGenTwoIssuer = response.data.hasGenTwoIssuer;
      this.hasGenTwoDigitalIssuer = response.data.hasGenTwoDigitalIssuer;
    },
    async loadFlowStateData() {
      const response = await this.apiClient.GetFlowStateData();
      this.flowState = response.data;
    },
    async postponeQuarterlyPayment(workflowId) {
      await this.apiClient.PostponeQuarterlyPayment(workflowId);
    },
    async archiveOrderAcceptanceTemplate(id) {
      await this.apiClient.ArchiveOrderAcceptance(id);
    },
    async loadAssetDataUpdateFlow() {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetAssetDataUpdateFlowVm();
      this.assetDataUpdateFlow = response.data;

      delete response.data.allEvents[0].assignedDateUtc;

      const workflowTemplate = {
        workflowDefinitionId: "AssetDataUpdateFlow",
        data: {
          workflowDisplayName: "Asset Data Update",
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },
    async loadProductUnderlyings(productId) {
      const response = await this.apiClient.GetProductUnderlyings(productId);
      this.productUnderlyings = response.data;
    },
    async initiateAssetDataUpdateFlow(payload) {
      this.resetWorkflowVMs();

      const response = await this.apiClient.InititateAssetDataUpdate(null, {
        ...payload,
        initiatorUserName: useAccountStore().user.userName,
      });
      return response;
    },
    async loadApprovalFlow() {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetApprovalFlowVm();
      this.approvalFlow = response.data;

      delete response.data.allEvents[0].assignedDateUtc;

      const workflowTemplate = {
        workflowDefinitionId: "ApprovalFlow",
        data: {
          workflowDisplayName: "Approval",
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },
    async loadProductApprovalProcessFlow() {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetApprovalFlowVm();
      this.productApprovalProcessFlow = response.data;

      delete response.data.allEvents[0].assignedDateUtc;

      const workflowTemplate = {
        workflowDefinitionId: "ApprovalFlow",
        data: {
          workflowDisplayName: "Approval",
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },
    async loadEntityApprovalFlow() {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetEntityApprovalFlowVm();
      this.entityApprovalFlow = response.data;

      delete response.data.allEvents[0].assignedDateUtc;

      const workflowTemplate = {
        workflowDefinitionId: "EntityApprovalFlow",
        data: {
          workflowDisplayName: "Entity Approval",
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },
    async loadCouponFlow(productEventId) {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetCouponFlowVm(productEventId);

      if (response.data?.selectedProduct !== null) {
        this.product = response.data.selectedProduct;
      }
      this.couponFlow = response.data;

      delete response.data.allEvents[0].assignedDateUtc;

      const workflowTemplate = {
        workflowDefinitionId: "CouponFlow",
        data: {
          workflowDisplayName: "Coupon",
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },
    async loadProductTerminationFlow() {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetProductTerminationFlowVm();
      this.productTerminationFlow = response.data;

      delete response.data.allEvents[0].assignedDateUtc;

      const workflowTemplate = {
        workflowDefinitionId: "ProductTerminationFlow",
        data: {
          workflowDisplayName: "Product Termination",
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },

    async loadPayingAgentAccountOpeningFlowVm() {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.PayingAgentAccountOpeningFlowDto();

      const workflowTemplate = {
        workflowDefinitionId: WorkflowType.PayingAgentAccountOpening,
        data: {
          workflowDisplayName: response.data.workflowDisplayName,
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },

    async loadProductApprovalProcess() {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetProductApprovalProcessFlowVm();

      const workflowTemplate = {
        workflowDefinitionId: WorkflowType.ProductApprovalProcess,
        data: {
          workflowDisplayName: response.data.workflowDisplayName,
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },

    async loadPartialRedemptionEventFlow(productEventId) {
      this.info = {};
      this.attachedFiles = [];

      const response = await this.apiClient.GetPartialRedemptionEventFlowVm(productEventId);
      this.partialRedemptionEventFlow = response.data;

      delete response?.data?.allEvents[0].assignedDateUtc;

      const workflowTemplate = {
        workflowDefinitionId: "PartialRedemptionEventFlow",
        data: {
          workflowDisplayName: "Partial Redemption Event",
          pendingEvent: response.data.allEvents[0],
          processedEvents: [],
          remainingEvents: [],
          allEvents: response.data.allEvents,
        },
      };
      this.setWorkflow(workflowTemplate);
    },
    async handleEntityFlow(payload) {
      return await this.apiClient.InitiateEntityApprovalFlow(null, payload);
    },
    async initiateApprovalFlow(payload) {
      return await this.apiClient.InititateApprovalFlow(null, {
        ...payload,
        initiatorUserName: useAccountStore().user.userName,
      });
    },
    async postWorkflowEvent(payload) {
      return await this.apiClient.PostWorkflowEvent(null, payload);
    },

    async loadPaymentFlowTypes(isin) {
      const response = await this.apiClient.GetPaymentFlowTypes(isin);
      this.paymentFlowTypes = response.data;
    },
    async initiateCouponFlow(payload) {
      return await this.apiClient.InititateCouponFlow(null, {
        ...payload,
        initiatorUserName: useAccountStore().user.userName,
      });
    },

    async initiateProductApprovalProcessFlow(
      payload: Components.Schemas.G2fpModelsFlowFlowsProductApprovalProcessFlowDtoInitiateProductApprovalProcessEventDto,
    ) {
      return await this.apiClient.InitiateProductApprovalProcess(null, payload);
    },

    async initiateProductTerminationFlow(payload) {
      return await this.apiClient.InitiateProductTerminationFlow(null, {
        ...payload,
        initiatorUserName: useAccountStore().user.userName,
      });
    },
    async initiatePartialRedemptionEventFlow(payload) {
      return await this.apiClient.InitiatePartialRedemptionEventFlow(null, payload);
    },
    async getAvailableCouponFlowTypes() {
      this.couponFlowTypesLoading = true;
      const response = await this.apiClient.GetAvailableCouponFlowTypes();
      this.couponFlowTypes = response.data;
      this.couponFlowTypesLoading = false;
    },
    async getAllAvailableCouponFlowTypes() {
      this.allCouponFlowTypesLoading = true;
      const response = await this.apiClient.GetAllAvailableCouponFlowTypes();
      this.allCouponFlowTypes = response.data;
      this.allCouponFlowTypesLoading = false;
    },
    async GetPlannedCoupons() {
      this.loading = true;
      const response = await this.apiClient.GetPlannedCoupons();
      this.plannedCoupons = response.data;
      this.loading = false;
    },
    async initiateBatchPayment(payingAgent, paymentInstructionIds) {
      const initiateResponse = await this.apiClient.InitiateBatchPaymentFlow(null, {
        isin: "dummy", // because backend expects this to have a value
        party: payingAgent,
        paymentInstructionIds: paymentInstructionIds,
        initiatorUserName: useAccountStore().user.userName,
      });

      return initiateResponse;
    },
    async uploadSignedBatchPayment(id, attachedFileGuids) {
      const batchWorkflow = await this.apiClient.GetFlowVmByIdPosApi(id);
      const pendingEvent = batchWorkflow.data.data?.pendingStage?.pendingEvents?.find(
        event => event.eventId === "UploadSignedPaymentInstruction",
      );
      pendingEvent.eventData.attachedFileGuids = attachedFileGuids;

      const postEventResponse = await this.apiClient.PostWorkflowEvent(null, pendingEvent);

      return postEventResponse;
    },
    async cancelBatchPayment(id) {
      const cancelResponse = await this.apiClient.CancelWorkflow(null, {
        reason: "cancelled from UI",
        workflowId: id,
      });

      return cancelResponse;
    },
  },
});

const helpers = {
  getBatchStatus(flowVm) {
    let status = BatchStatus.NotStarted;

    if (flowVm?.data?.terminationReason) {
      status = BatchStatus.Failed;
    } else if (flowVm?.data?.isCancelled) {
      status = BatchStatus.Cancelled;
    } else if (
      flowVm?.status === "Runnable" &&
      flowVm?.data?.cancelOrUploadSignedPaymentInstructionFired
    ) {
      status = BatchStatus.Pending;
    } else if (
      flowVm?.status === "Runnable" &&
      flowVm?.data?.pendingStage?.pendingEvents?.length > 0
    ) {
      status = BatchStatus.Draft;
    } else if (flowVm?.status === "Complete" && flowVm?.data?.attachedFileGuids?.length > 0) {
      status = BatchStatus.Completed;
    }

    return status;
  },
};
