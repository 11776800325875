import { defineStore } from "pinia";
import { useAccountStore } from "./account";
import * as Sentry from "@sentry/vue";
import { Paths } from "@/assets/docs/client.d";

export interface ApiState {
  options: Paths.GetClientPortalOptions.Responses.$200 | {};
  backendClientErrors: Response | null;
}

export const useApiStore = defineStore("ApiStore", {
  state: (): ApiState => ({
    options: {},
    backendClientErrors: null,
  }),
  actions: {
    async init(vue) {
      const account = useAccountStore();

      // Add a response interceptor
      this.apiClient.interceptors.response.use(
        response => response,
        async error => {
          // clear previous messages
          vue.$toast.removeAllGroups();

          this.backendClientErrors = null;

          // Do something with response error
          if (error.response?.data?.title || error.response?.data?.detail) {
            // TAH: This is a ProblemDetail message
            vue.$toast.add({
              severity: "error",
              summary: error.response.data.title,
              detail: error.response.data.detail,
            });
          } else if (error.response) {
            switch (error.response.status) {
              case 400:
                if (error.response?.data?.detail) {
                  vue.$toast.add({
                    severity: "error",
                    summary: error.response?.data?.title,
                    detail: error.response?.data?.detail,
                  });
                } else {
                  vue.$toast.add({
                    severity: "error",
                    summary: "The input provided was not correct",
                  });
                }

                this.backendClientErrors = error.response.data.errors;
                return error.response;
              case 401:
                await account.logout(false);
                vue.$router.push({ name: "Login" });
                break;
              case 403:
                vue.$toast.add({
                  severity: "warn",
                  summary:
                    error.response.data.detail ??
                    "You are not allowed to access that section/resource.",
                });
                return "You are not allowed to access that section/resource.";
              case 500:
                Sentry.showReportDialog({
                  user: {
                    name: account.user.firstName + " " + account.user.lastName,
                    email: account.user.email,
                  },
                  title: "Sorry, we've had a problem (on our end))",
                  subtitle: "The Gentwo Development team has already been notified.",
                  subtitle2:
                    "If you'd like to help us further, please tell us what happened below. If this is impacting your business, please email dev@g2fp.com or call us on +41 44 512 75 00",
                });
            }
          } else if (error.message === "Network Error") {
            vue.$toast.add({
              severity: "warn",
              summary: "The API cannot be reached.",
              detail: "Please check your connection and refresh the page.",
            });
          } else if (!error.response) {
            vue.$toast.add({
              severity: "error",
              summary: "An error occured during an API call. Please refresh the page.",
            });
          }

          return Promise.reject(error);
        },
      );

      await this.loadOptions();
    },
    async loadOptions() {
      const response = await this.apiClient?.GetClientPortalOptions();
      this.options = response?.data || {};
    },
    async clearBackendErrors() {
      this.backendClientErrors = null;
    },
  },
});
